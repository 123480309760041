.blog-archives a:hover img {
	opacity: 0.5;
	transition: all 0.3s linear;
}

.blog-row {
	background-color: #F2F3F6;
}

.blog-img {
	display: block;
	height: 285px;
	background: #83889B;
	overflow: hidden;
}

.blog-img img {
	/*width: 100%;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);*/
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.blog-archive {
	padding: 20px;
}

.blog-archive h2 {
	margin-top: 0;
	font-size: 24px;
}

.blog-archive h2:after {
	width: 50px;
}

.blog-archive a {
	margin-top: 20px;
}

.blog-archives .container {
	position: relative;
}

.blog-archives .row section section {
	padding: 0;
	float: left;
}

.blog-archives .row:first-child .blog-row {
	border-radius: 10px 10px 0 0;
	overflow: hidden;
}

.blog-archives .row:last-child .blog-row {
	border-radius: 0 0 10px 10px;
	overflow: hidden;
}

.blog-archives .row:nth-child(odd) section section {
	float: right;
}

.blog-loading {
	text-align: center;
	position: absolute;
	width: 100%;
	left: 0;
	top: 50%;
	margin-top: -25px;
}

@media (max-width: 768px) {
	.blog-archives .row:nth-child(odd) section section {
		float: none;
	}

	.blog-img {
		height: auto;
	}

	/*.blog-img img {
		top: auto;
		left: auto;
		transform: none;
	}*/
}
