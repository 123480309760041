.features {
    /*background: #fafafa;*/
}

.features .row {
    margin-bottom: 40px;
}

.features .row:last-child {
    margin-bottom: 0;
}

.features .card {
    border: 1px solid #D9DCE3;
    transition: all ease 0.2s;
    /*background: #fff;*/
    background: #fafafa;
    border-radius: 10px;
}

.features hr {
    margin: 20px 0;
}

.features .card-title {
    margin: 10px 0 0;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 700;
}

.features .card-price {
    /*font-size: 40px;*/
    margin: 0;
}

.features .card-price .period {
    font-size: 12px;
}

.features ul li {
    margin-bottom: 10px;
    font-size: 13px;
}

.features .text-muted {
    opacity: 0.7;
}

.features .btn {
    padding: 10px;
    opacity: 0.7;
    transition: all 0.2s;
}

.features .btn i {
    margin-right: 7px;
}

.features .card:hover {
    margin-top: -5px;
    margin-bottom: 5px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.features .card:hover .btn {
    opacity: 1;
}

.code.features {
    background: #fff;
}

.code.features .row:first-child {
    margin-bottom: 0;
}

.code.features .card {
    background: #fafafa;
}

.code.features ul {
    column-count: 2;
}

.code.features .btn.btn-block {
    max-width: 300px;
    margin: auto;
}

@media (max-width: 768px) {
    .code.features ul {
        column-count: 1;
    }

    .code.features .btn.btn-block {
        max-width: 100%;
        margin: 0;
    }
}