.home-page-content {
    position: relative;
}

.home-page-content video {
    width: 100%;
    height: auto;
}

.home-page-content .landing-info {
    /*position: absolute;
    top: 0;
    width: 100%;
    padding-top: 10%;*/
    background: url("./home-bg.jpg") no-repeat center #e6e6e6;
    background-attachment: fixed;
    background-size: cover;
    padding: 150px 0;
}

.home-page-content .landing-info h1 {
    color: #2A2939;
}

.home-page-content .landing-info h1 {
    margin-top: 60px;
    font-size: 40px;
}

.home-page-content .landing-info p {
    margin-bottom: 40px;
    font-size: 18px;
}

.home-about,
.use-cases {
    background: #fafafa;
}

.home-solution h3 {
    font-size: 40px;
    margin-bottom: 40px;
}

.home-solution section i {
    color: #fff;
    padding: 13px 10px;
    background: #83889B;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    text-align: center;
    font-size: 20px;
}

.home-solution section h4 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
}

.use-cases .case {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    position: relative;
}

.use-cases .case i {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 30px;
    color: #CC3333;
}

.use-cases h2 {
    font-size: 24px;
}

.use-cases h2:after {
    width: 50px;
}

.use-cases p:last-child {
    margin-bottom: 0;
}

.use-cases .small-note {
    margin-top: 20px;
}

.home-blog {
    background: url("./blog-bg.jpg") no-repeat center;
    background-attachment: fixed;
    background-size: cover;
    padding: 100px 0;
}

.home-blog h2 {
    color: #fff;
}

.home-blog h2:after {
    background: #636781;
}

.latest-blog {
    background: rgba(255, 255, 255, 0.70);
    padding: 40px;
    border-radius: 10px;
}

.latest-blog h3 {
    color: #CC3333;
    margin-top: 0;
}

.latest-blog p {
    margin-bottom: 20px;
}

@media (max-width: 1500px) {
    .home-page-content .landing-info {
        padding-top: 7%;
    }
}

@media (max-width: 1200px) {
    .home-page-content .landing-info {
        padding-top: 5%;
    }
}

@media (max-width: 992px) {
    .home-page-content .landing-info h1 {
        font-size: 25px;
    }

    .use-cases .case {
        margin-bottom: 30px;
    }

    .use-cases section:last-child .case {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .home-page-content video {
        display: none;
    }

    .home-page-content .landing-info {
        position: static;
        padding-top: 120px;
        padding-bottom: 80px;
        text-align: center;
    }

    .home-page-content .landing-info img {
        display: none;
    }

    .home-page-content .landing-info h1 {
        font-size: 30px;
        margin-top: 0;
    }

    .home-page-content .landing-info p {
        margin-bottom: 20px;
        font-size: 15px;
    }

    .use-cases h2 {
         font-size: 22px;
     }
}
