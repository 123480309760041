.why-us section i {
    color: #fff;
    padding: 9px;
    float: left;
    background: #CC3333;
    margin-right: 15px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
}

.why-us section h3 {
    margin-top: 3px;
    margin-bottom: 20px;
}

.testimonials {
    background: #fafafa;
}

.testimonials img {
    border: 2px solid #D9DCE3;
}

.testimonials h3 {
    margin-bottom: 20px;
}