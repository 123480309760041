#contactForm {
	background: #F2F3F6;
	padding: 40px;
	margin-top: 40px;
    border-radius: 10px;
}

#contactForm input, #contactForm textarea {
	margin-bottom: 20px;
	padding: 8px 10px;
	border: 1px solid #D9DCE3;
    border-radius: 5px;
}

#contactForm input {
    width: 48%;
}

#contactForm input[type="submit"] {
	padding: 8px 20px;
    border-radius: 50px;
    width: auto;
}

#contactForm input:nth-of-type(1), #contactForm input:nth-of-type(3) {
	margin-right: 4%;
}

#contactForm textarea {
	width: 100%;
	resize: vertical;
}

@media (max-width: 768px) {
	#contactForm input, #contactForm input[type="submit"] {
      width: 100%;
    }

    #contactForm input:nth-of-type(1), #contactForm input:nth-of-type(3) {
	margin-right: 0;
}
}
