/* Color Palette
#CC3333 - Red
#2A2939 - Very Dark Blue
#414367 - Dark Blue
#636781 - Medium Blue
#83889B - Light Blue
#AEB1C1 - Very Light Blue
#D9DCE3 - Light Grey
 */

html {
	height: 100%;
}

body {
    font-family: 'Lato', Arial, sans-serif;
    font-size: 16px;
	min-height: 100%;
	position: relative;
    color: #414367;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Comfortaa', Arial, sans-serif;
}

h1, h2, h3 {
    color: #CC3333;
}

h4, h5, h6 {
    color: #2A2939;
}

h2 {
    margin-bottom: 40px;
}

h2:after {
    content: "";
    display: block;
    height: 2px;
    width: 100px;
    background: #636781;
    margin-top: 20px;
}

a {
    color: #CC3333;
}

a:focus, a:hover {
    color: #636781;
    text-decoration: none;
	transition: all 0.3s linear;
}

.btn {
    border-radius: 50px;
}

.btn-primary {
    color: #fff;
    background-color: #CC3333;
    border-color: #CC3333;
    padding: 8px 20px;
    text-transform: uppercase;
	transition: all 0.3s linear;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.focus, .btn-primary:focus, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #636781;
    border-color: #636781;
}

.btn:focus,
.btn.focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.page-content {
	padding-bottom: 188px;
}

.row-block {
    padding: 40px 0;
}

.landing-strip {
    background: url("../../pages/Home/home-bg.jpg") no-repeat center #e6e6e6;
    background-attachment: fixed;
    background-size: cover;
    padding: 120px 0 40px;
}

.landing-strip h1 {
    font-size: 45px;
    color: #636781;
    margin-top: 0;
}

.landing-strip p {
    font-size: 19px;
    color: #636781;
}

.fa-spin {
	font-size: 16px;
	color: #2A2939;
}

embed,
iframe,
object,
video {
    max-width: 100%;
    border: 0;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
	.page-content {
		padding-bottom: 0;
		min-height: 600px;
	}

    .landing-strip h1 {
        font-size: 30px;
    }

    .landing-strip p {
        font-size: 15px;
    }

    h2 {
        margin-top: 0;
    }
}
