footer {
    background: #2A2939;
    color: #fff;
    position: absolute;
    width: 100%;
    bottom: 0;
}

footer a {
    color: #AEB1C1;
}

footer a:hover {
    color: #83889B;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer .social {
    text-align: right;
}

footer .social a {
    color: #fff;
    background: #CC3333;
    padding: 7px;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    margin-left: 5px;
    border-radius: 50px;
}

footer .social a:hover {
    background: #636781;
}

footer .btn-primary {
    display: inline-block;
    margin-top: 10px;
    color: #fff;
}

footer h4 {
    color: #ffffff;
}

footer .footer-links {
    text-align: right;
    margin-top: 20px;
    font-weight: 300;
}

footer .copyright {
    font-weight: 300;
    margin-bottom: 0;
    font-size: 14px;
}

@media (max-width: 768px) {
    footer {
        text-align: center;
        position: static;
    }

    footer .social {
        text-align: center;
        margin-top: 20px;
    }

    footer .footer-links {
        text-align: center;
        margin-bottom: 20px;
    }
}
