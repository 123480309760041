.innerblog-page-content .blog-loading {
    margin-top: -95px;
}

.blog-post-img {
    height: 250px;
    background: #F2F3F6;
    margin: 0 0 20px;
    border-radius: 5px;
    overflow: hidden;
}

.blog-post-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.blog-text > section > img {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .blog-post-img {
        height: auto;
    }

    .blog-post-img img {
        width: 100%;
        height: auto;
    }
}