/*
.our-services.row-block {
    padding-bottom: 0;
}
*/

.our-services .tile {
    padding: 60px 40px;
    text-align: center;
    border: 1px solid #D9DCE3;
    margin-bottom: 40px;
    min-height: 350px;
    border-radius: 10px;
    transition: all ease 0.2s;
    background: #fafafa;
}

.our-services .tile:hover {
    margin-top: -5px;
    margin-bottom: 5px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.our-services .tile i {
    font-size: 40px;
    color: #CC3333;
    margin-bottom: 15px;
}

.our-services .tile h3 {
    font-size: 21px;
    color: #414367;
}

.our-services ul {
    list-style: none;
    padding: 0;
}

.our-services li {
    padding-left: 20px;
}

.our-services li:before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: inline-block;
    margin-left: -20px;
    width: 25px;
    color: #CC3333;
}

@media (max-width: 768px) {
	.our-services .tile {
        min-height: 0;
    }
}